<template>
    <div class="product-details" v-if="productDetail">
        <Productheader
            :activeMenu="activeMenu"
            :isPaidStatus="isPaidStatus"
            @buy="toOrderVerifyPage"
            @study="toCoursePage"
            @change="onSwitchMenu"
            v-show="isShowProductheader"
            class="header"
        />
        <Header />
        <div class="content">
            <div class="cover-bg" :style="`background-image: url(${productDetail.bannerImageUrl});`"></div>
            <div class="container">
                <div class="product-info">
                    <img :src="productDetail.bannerImageUrl" />
                    <div class="details">
                        <div class="title">{{ productDetail.title }}</div>
                        <div class="teacher-name">
                            <img src="https://res.peki.vip/20230317/7156799865d54c78830d04bb5c588f26.png" />
                            <span>{{ productDetail.courseTeacher ? productDetail.courseTeacher.name : '-' }}</span>
                        </div>
                    </div>
                </div>
                <div class="paid-container" v-if="isPaidStatus">
                    <div class="price">
                        已购买
                        <span>（价格：¥{{ productDetail.price / 100 }}）</span>
                    </div>
                    <div>加入胡桃文化，带你成功上岸！</div>
                    <div @click="toCoursePage" class="study-btn">立即学习</div>
                </div>
                <div class="pay-container" v-else>
                    <div class="price">¥{{ productDetail.price / 100 }}</div>
                    <div>加入胡桃文化，带你成功上岸！</div>
                    <div @click="toOrderVerifyPage" class="buy-btn">立即支付</div>
                </div>
                <div class="menu">
                    <div @click="onSwitchMenu('introduce')" :class="activeMenu === 'introduce' ? 'active' : ''">
                        课程介绍
                    </div>
                    <div @click="onSwitchMenu('catalog')" :class="activeMenu === 'catalog' ? 'active' : ''">
                        课程目录
                    </div>
                </div>
                <div class="introduce" v-show="activeMenu === 'introduce'">
                    <img v-for="(item, index) in productDetail.descImageUrls" :key="index" v-lazy="item" />
                </div>
                <div class="catalog" v-show="activeMenu === 'catalog'">
                    <div class="stage" v-for="item in stageList" :key="item.id">
                        <div class="stage-title">
                            <span>{{ item.title }}</span>
                            <span class="btn" @click="item.isOpen = false" v-if="item.isOpen">
                                收起 <van-icon name="arrow-up" />
                            </span>
                            <span class="btn" @click="item.isOpen = true" v-else>
                                查看全部课程 <van-icon name="arrow" />
                            </span>
                        </div>
                        <div
                            v-show="item.isOpen"
                            class="course-item"
                            @click="onLockCourse"
                            v-for="num in item.lessonCnt || 0"
                            :key="num"
                        >
                            <img
                                class="play-icon"
                                src="https://res.peki.vip/20230317/29e990f2aad64651911e06621e1e01a4.png"
                            />
                            <span>第{{ num }}课</span>
                            <img
                                class="lock-icon"
                                src="https://res.peki.vip/20230317/a33401368bbb445b8c16747ddcde6425.png"
                            />
                            <img
                                class="homework-icon"
                                src="https://res.peki.vip/20230317/9037e7a09e0546f2b9aff8235e00308f.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Fotter />

        <img
            @click="onToTop"
            v-show="isShowTopIcon"
            class="top-icon"
            src="https://res.peki.vip/20230317/9f3ff16b049d4705919dd9668df9d82f.png"
        />
    </div>
</template>
<script>
import Header from '@/components/layout/Header.vue'
import Fotter from '@/components/layout/Fotter.vue'
import Productheader from './components/Productheader'
import { coursePackageList } from '@/api/courseApi.js'
import { getPaidStatus, getStages } from '@/api/userApi.js'
import { mapGetters } from 'vuex'

export default {
    name: 'productDetails',
    components: { Header, Fotter, Productheader },
    computed: { ...mapGetters(['deviceInfo', 'userInfo']) },
    watch: {
        userInfo: function () {
            this.getPaidStatusApi()
        },
    },
    data() {
        return {
            productDetail: null,
            isShowProductheader: false,
            activeMenu: 'introduce',
            isShowTopIcon: false,

            isPaidStatus: false,
            stageList: [],
        }
    },
    created() {
        this.getProductDetailApi()
        this.getPaidStatusApi()
    },
    mounted() {
        // 是否购买
        if (this.deviceInfo.pc) {
            window.addEventListener('scroll', this.onPageScroll)
        }
    },
    destroyed() {
        if (this.deviceInfo.pc) {
            window.removeEventListener('scroll', this.onPageScroll)
        }
    },
    methods: {
        onPageScroll() {
            if (document.documentElement.scrollTop > 420) {
                this.isShowProductheader = true
            } else {
                this.isShowProductheader = false
            }

            if (document.documentElement.scrollTop > 700) {
                this.isShowTopIcon = true
            } else {
                this.isShowTopIcon = false
            }
        },
        onToTop() {
            document.documentElement.scrollTop = 0
        },
        onSwitchMenu(menu) {
            this.activeMenu = menu
        },
        onLockCourse() {
            if (this.isPaidStatus) {
                this.toCoursePage()
            } else {
                this.$toast('立即购买,解锁课程')
            }
        },
        toOrderVerifyPage() {
            if (this.userInfo) {
                const { packageNo } = this.$route.params
                this.$router.push(`/orderVerify/${packageNo}`)
            } else {
                this.$toast('请先登录')
                this.$store.dispatch('user/showLoginDialog')
            }
        },
        toCoursePage() {
            this.$router.push('/course')
        },
        getPaidStatusApi() {
            const { packageNo } = this.$route.params
            if (packageNo && this.userInfo && this.userInfo.id) {
                getPaidStatus(packageNo).then((res) => {
                    if (res.data && res.data.status) {
                        this.isPaidStatus = true
                    }
                })
            }
        },
        getStagesApi() {
            const { packageNo } = this.$route.params
            getStages(packageNo).then((res) => {
                res.data.forEach((item) => {
                    item.isOpen = false
                })
                this.stageList = res.data
            })
        },
        async getProductDetailApi() {
            const { packageNo } = this.$route.params
            const res = await coursePackageList(packageNo)
            let productDetail = {}
            if (res.data && res.data.length !== 0) {
                productDetail = res.data[0]
                productDetail.descImageUrls = productDetail.descriptionImageUrl.split(',')
                productDetail.sellExtra.tagList = []
                if (productDetail.sellExtra && productDetail.sellExtra.tags) {
                    productDetail.sellExtra.tagList = productDetail.sellExtra.tags.split(',')
                }
            }
            this.productDetail = productDetail
            const stage = await getStages(packageNo)
            stage.data.forEach((item) => {
                item.isOpen = false
            })
            this.stageList = stage.data
            document.title = this.productDetail ? this.productDetail.title : ''
        },
    },
}
</script>
<style lang="scss" scoped>
.product-details {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .content {
        flex-grow: 1;
    }
}
.product-details {
    background: $theme-bg-color;
    min-height: 100vh;
    .cover-bg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 330px;
        filter: blur(16px);
        background-size: cover;
    }
    .container {
        position: relative;
        z-index: 1;
        max-width: $center-width;
        padding: 24px;
        margin: 0 auto;
    }
    .product-info {
        width: 100%;
        height: 232px;
        background: #ffffff;
        border-radius: 4px;
        display: flex;
        box-sizing: border-box;
        padding: 24px;
        > img {
            width: 328px;
            height: 184px;
            object-fit: cover;
            margin-right: 24px;
        }
        .title {
            font-size: 36px;
            color: #33312f;
            line-height: 36px;
            font-weight: 700;
        }
        .teacher-name {
            margin: 20px 0 80px;
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #66625e;
            line-height: 16px;
            > img {
                width: 32px;
                height: 32px;
                background: none;
                margin-right: 8px;
            }
        }
        .details {
            flex-grow: 1;
        }
    }
    .paid-container,
    .pay-container {
        margin: 24px 0;
        width: 100%;
        height: 100px;
        background: #ffffff;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #66625e;
        padding: 0 24px;
        box-sizing: border-box;
        .price {
            font-size: 44px;
            color: #33312f;
            display: flex;
            align-items: center;
            font-weight: 700;
            span {
                font-size: 14px;
                color: #66625e;
            }
        }
        .study-btn,
        .buy-btn {
            width: 156px;
            height: 40px;
            background: $theme-color;
            border-radius: 4px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            cursor: pointer;
        }
        .study-btn {
            background: #f08e0e;
        }
    }
    .menu {
        width: 100%;
        height: 64px;
        background: #ffffff;
        display: flex;
        > div {
            width: 172px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #33312f;
            cursor: pointer;
        }
        .active {
            color: $theme-color;
            font-weight: 700;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                bottom: 15px;
                width: 28px;
                height: 2px;
                background: $theme-color;
            }
        }
    }
    .introduce {
        width: 100%;
        background: #ffffff;
        padding: 32px 40px;
        box-sizing: border-box;
        > img {
            width: 100%;
            display: block;
        }
    }
    .catalog {
        width: 100%;
        background: #ffffff;
        padding: 32px 40px;
        box-sizing: border-box;
        border-top: 1px solid rgba(230, 230, 230, 1);
        .stage {
            .stage-title {
                height: 64px;
                background: #f5f5f5;
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #33312f;
                position: relative;
                &::before {
                    content: '';
                    display: block;
                    width: 8px;
                    height: 24px;
                    background: #f0941d;
                    margin-right: 20px;
                }
                .btn {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 20px;
                    height: 100%;
                    font-size: 14px;
                    color: #99948d;
                    line-height: 14px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .van-icon {
                        margin-left: 6px;
                    }
                }
            }
            .course-item {
                height: 64px;
                font-size: 14px;
                color: #66625e;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 24px 0 56px;
                box-shadow: 0px 1px 0px 0px rgba(211, 211, 211, 0.5);
                span {
                    flex-grow: 1;
                }
                > img {
                    background: none;
                }
                .play-icon {
                    width: 18px;
                    height: 14px;
                    margin-right: 12px;
                }
                .lock-icon {
                    width: 16px;
                    height: 16px;
                }
                .homework-icon {
                    width: 16px;
                    height: 16px;
                    margin-left: 16px;
                }
            }
        }
    }
    .top-icon {
        position: fixed;
        bottom: 40px;
        right: 40px;
        z-index: 99;
        cursor: pointer;
    }
}

@media screen and (max-width: 720px) {
    .product-details {
        background: #ffffff;
        .header {
            display: none;
        }
        .cover-bg {
            display: none;
        }
        .container {
            width: auto;
            padding: 16px 16px 0;
            margin: 0 auto;
        }
        .product-info {
            width: 100%;
            height: auto;
            padding: 0;
            border-radius: 0;
            background: #f7f7f7;
            flex-direction: column;
            > img {
                width: 100%;
                height: auto;
                margin-right: 0;
            }
            .title {
                font-size: 20px;
                margin: 16px 0 0 16px;
            }
            .teacher-name {
                display: none;
            }
        }
        .paid-container,
        .pay-container {
            padding: 24px 0;
            margin: 0;
            width: 100%;
            height: auto;
            background: #f7f7f7;
            border-radius: 0;
            flex-direction: column;
            align-items: flex-start;
            font-size: 14px;
            color: #66625e;
            padding: 0 16px;
            box-sizing: border-box;
            .price {
                margin: 12px 0 8px;
                font-size: 32px;
            }
            .study-btn,
            .buy-btn {
                width: 120px;
                margin: 24px auto 24px;
            }
        }
    }
}
</style>
